<template>
  <div class="fleetDetail">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex flex-wrap px-14 mt-10 md:flex-row-reverse">
      <div class="w-full lg:pl-8 lg:w-2/5 mb-4">
        <SlideUp>
          <div
            enctype="multipart/form-data"
            novalidate
            class="bg-blueGray-200 p-5 relative hover:bg-blueGray-300 flex justify-center items-center w-full rounded-md"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <div
              v-show="url != ''"
              class="relative flex justify-center items-center content-center"
            >
              <div class="relative flex justify-center items-center">
                <div
                  @click="removeFile"
                  class="absolute -right-3 -top-3 shadow-md bg-white rounded-full w-7 h-7 flex justify-center items-center cursor-pointer"
                >
                  <i class="fas fa-times-circle" />
                </div>
                <img :src="url" />
              </div>
              <div
                v-show="hover"
                class="h-full w-full opacity-20 bg-green-500 absolute flex items-center justify-items-center"
              />
              <div
                v-show="hover"
                class="bg-white absolute flex items-center justify-items-center p-8"
                @click="startUploadBrowser"
              >
                {{ $t("upload new profile image") }}
              </div>
            </div>

            <div
              v-show="url == ''"
              class="border-4 w-full border-dashed border-gray-300 p-5 hover:border-gray-400 flex justify-center items-center content-center"
            >
              <input
                type="file"
                ref="profileImage"
                @change="fileChange"
                class="absolute top-0 left-0 w-full h-full opacity-0"
                capture="camera"
              />
              <div>
                <h3 class="text-2xl font-bold">
                  {{ $t("draganddrop") }}
                </h3>
                <div class="text-md">{{ $t("upldinst") }}</div>
                <div class="text-md">
                  {{ $t("browse") }}
                </div>
              </div>
            </div>
          </div>
        </SlideUp>
        <SlideUp>
          <ApiKeys />
        </SlideUp>
      </div>
      <div class="w-full lg:w-3/5">
        <SlideUp>
          <Form :form="formData" formName="user settings" />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";
import { getFieldValue } from "../helpers/form";
import ApiKeys from "../components/ApiKeys.vue";

export default {
  name: "UserSettings",
  components: {
    SlideUp,
    ApiKeys,
    Form
  },
  data() {
    let formData = [
      {
        section: "User Information",
        boundBy: "account",
        dispatch: "updateUserInfo/updateUserInfo",
        fields: [
          { name: "email", type: "readonly", key: "email", value: "" },
          { name: "name", type: "text", key: "name", value: "" }
        ]
      },
      {
        section: "Log-in information",
        boundBy: "account",
        dispatch: "updateUserInfo/changePassword",
        fields: [
          {
            name: "current password",
            type: "password",
            showPassword: false,
            key: "current_password",
            value: ""
          },
          {
            name: "password",
            type: "password",
            showPassword: false,
            key: "password",
            value: ""
          },
          {
            name: "repeat password",
            type: "password",
            showPassword: false,
            key: "password_confirmation",
            value: ""
          }
        ]
      },
      {
        section: "notifications",
        boundBy: "account",
        dispatch: "updateUserInfo/updateUserInfo",
        fields: [
          {
            parentKey: "info",
            name: "send ticket notification emails",
            type: "checkbox",
            key: "send_ticket_email",
            value: false
          }
        ]
      }
    ];
    if (this.$store.state.accounts.user.account_type == "admin") {
      formData = formData.concat({
        section: "Company",
        boundBy: "company",
        dispatch: "companies/updateCompany",
        fields: [
          {
            name: "upload",
            type: "upload",
            action: "company_logo",
            removeAction: "remove_company_logo",
            key: "logo",
            removeFile: () => {
              this.$store.dispatch("companies/removeLogo", {
                company: { action: "remove_company_logo" },
                cb: () => ""
              });
            },
            path: "",
            cb: () =>
              this.$store.dispatch("companies/getMyCompany", {
                cb: () => this.boundData()
              })
          },
          {
            name: "name",
            type: "text",
            key: "name",
            value: "",
            width: "full"
          },
          {
            parentKey: "address",
            key: "street",
            name: "street",
            type: "text",
            value: ""
          },
          {
            parentKey: "address",
            key: "city",
            name: "city",
            type: "text",
            value: ""
          },
          {
            parentKey: "address",
            key: "state",
            name: "state",
            type: "text",
            value: ""
          },
          {
            parentKey: "address",
            key: "postal_code",
            name: "postal_code",
            type: "text",
            value: ""
          },
          {
            parentKey: "address",
            key: "country",
            name: "country",
            type: "text",
            value: ""
          },
          {
            parentKey: "custom_data",
            name: "google analytics ID",
            type: "text",
            key: "ga_id",
            value: "",
            width: "full"
          }
        ]
      });
    }
    return {
      hover: false,
      profileImage: [],
      formData
    };
  },
  computed: {
    url() {
      return this.me.profile_image;
    },
    me() {
      return this.$store.state.accounts.user;
    }
  },
  watch: {
    me() {
      this.boundData();
    }
  },
  methods: {
    startUploadBrowser() {
      this.$refs.profileImage.click();
    },
    removeFile() {
      this.$store.dispatch("updateUserInfo/removeProfileImage");
    },
    fileChange() {
      let formData = new FormData();
      formData.append("file", this.$refs.profileImage.files[0]);
      formData.append("action", "profile_image");
      this.$store.dispatch("updateUserInfo/uploadProfileImage", formData);
    },
    boundData() {
      // extract and fix in future
      this.formData.forEach(({ fields, boundBy }) => {
        fields.forEach(field => {
          if (field.type == "upload") {
            let c = this.$store.state.companies?.company;
            field.path = c?.[field.key]?.path;
          } else if (field.key == "send_ticket_email") {
            let email_notification =
              this.$store.state.accounts?.user?.info?.send_ticket_email == true
                ? true
                : false;
            field.value = email_notification;
          } else if (
            !(
              field.key == "current_password" ||
              field.key == "password" ||
              field.key == "repeat_password"
            )
          ) {
            let obj =
              boundBy == "account"
                ? this.$store.state.accounts.user
                : this.$store.state.companies.company;

            field.value = getFieldValue(field, obj);
          }
        });
      });
    }
  },
  mounted() {
    this.$store.dispatch("accounts/me");
    if (this.$store.state.accounts.user.account_type == "admin") {
      this.$store.dispatch("companies/getMyCompany", {
        cb: () => this.boundData()
      });
    }
    this.boundData();
  }
};
</script>
