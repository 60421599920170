<template>
  <div
    class="p-5 relative bg-white w-full rounded-md my-6"
    v-if="$store.state.accounts.user.account_type == 'admin'"
  >
    <button
      @click="$store.dispatch('updateUserInfo/enableApiToken')"
      v-if="!$store.state.accounts.api_token"
      class="focus:outline-none font-semibold text-gray-400 uppercase text-xs"
    >
      {{ $t("enable developers token") }}
    </button>
    <button
      @click="$store.dispatch('updateUserInfo/disableApiToken')"
      v-if="$store.state.accounts.api_token"
      class="focus:outline-none font-semibold text-gray-400 uppercase text-xs"
    >
      {{ $t("disable developers token") }}
    </button>
    <div class="text-xs relative" v-if="$store.state.accounts.api_token">
      <div class="bg-blueGray-100 w-full rounded px-5 py-3" v-if="show">
        <i class="fas fa-eye mr-1" @click="toggleShow" />
        {{ $store.state.accounts.api_token }}
        <i
          class="far fa-object-ungroup flex justify-end"
          @click="copyClipBoard"
        />
        <span
          v-if="copySuccessText"
          class="absolute top-8 -right-2 bg-coolGray-300 text-coolGray-800 px-5 py-2 rounded"
        >
          {{ $t("api token copied!") }}
        </span>
      </div>
      <div class="bg-blueGray-100 w-full rounded px-5 py-3" v-else>
        <i class="fas fa-eye-slash mr-1" @click="toggleShow" />
        ************************************************************
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApkiKeys",
  data() {
    return {
      show: false,
      copySuccessText: false
    };
  },
  methods: {
    copyClipBoard() {
      navigator.clipboard.writeText(this.$store.state.accounts.api_token);
      this.copySuccessText = true;
      setInterval(() => (this.copySuccessText = false), 2000);
    },
    toggleShow() {
      this.show = !this.show;
      setInterval(() => (this.show = false), 7000);
    }
  }
};
</script>
